import React, { MouseEventHandler, useState } from 'react';
import Modal from 'react-modal';
import Logo from '../logo';
import SelectRegion from '../SelectRegion';
import { ReactComponent as RegionPin } from './mappin.svg';
import { ReactComponent as Clear } from './clear.svg';
import './Header.scss';

type HeaderType = {
  region: string;
  page: 'main' | 'map';
  setPage: (page: 'main' | 'map') => void;
};

Modal.setAppElement('#root');

const Header: React.FC<HeaderType> = ({ region, page, setPage }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <header className={`map-page-header ${page}`}>
      <div className="container">
        <div className="map-page-header__wrap">
          <button className="map-page-header__menu" onClick={() => setMenuIsOpen(!menuIsOpen)}>
            {menuIsOpen ? <Clear /> : 'меню'}
          </button>
          <div className="map-page-header__logo">
            <Logo />
          </div>
          <button type="button" onClick={openModal} className="map-page-header__region">
            <RegionPin />
            <span>{region}</span>
          </button>
          <nav className={`map-page-header__nav ${menuIsOpen ? 'open' : ''}`}>
            <a href="#"
              onClick={(e) => {
                e.preventDefault();
                setMenuIsOpen(false);
                setPage('map');
              }}
              className={`${page === 'map' ? 'active' : ''}`}
            >
              Центры доставки на карте
            </a>
            <a href="#"
              onClick={(e) => {
                e.preventDefault();
                setMenuIsOpen(false);
                setPage('main');
              }}
              className={`${page === 'main' ? 'active' : ''}`}
            >
              Что такое центры доставки
            </a>
            <a target="_blank" href="https://dostavka.yandex.ru/for-courier/">Как стать курьером</a>
            {/* <a href="#">Записаться на обучение</a> */}
          </nav>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Выбор региона"
        overlayClassName="select-region-overlay"
        className="select-region-content"
      >
        <button
          type="button"
          className="select-region-content__clear"
          aria-label="Закрыть модальное окно"
          onClick={closeModal}
        >
          <Clear />
        </button>
        <h3 className="select-region-content__title">Укажите свой регион</h3>
        <SelectRegion onFetchRegion={closeModal} />
      </Modal>
    </header>
  );
};

export default Header;
