import { AxiosResponse } from 'axios';
import { axiosGet } from '../../helpers/api';
import { CitiesType, CityItemType } from './types';

export const apiFetchCity = async () => {
  try {
    const res: AxiosResponse<CityItemType> = await axiosGet('v1/city');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiFetchCities = async (slug?: string) => {
  try {
    const res: AxiosResponse<CitiesType> = await axiosGet(`v1/cities${slug ? `?q=${slug}` : ''}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};