import React, { useState } from 'react';
import classnames from 'classnames';
import MapBlock from '../../../components/MapBlock';
import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import MapButton from '../../../components/MapButton';
import { ReactComponent as FiltersSvg } from './filters.svg';
import { FiltersType } from './index';
import './Filters.scss';
import { FilterType, MapItemType } from '../../../store/regions';

type FiltersViewType = {
  activeFilter: string;
  filtersData: FilterType[];
  setActiveFilterHandler: (filters: string) => void;
  regionItems: MapItemType[]
} & FiltersType;

const Filters: React.FC<FiltersViewType> = ({ 
  isActive, activeFilter, filtersData, 
  setActiveFilterHandler: setActiveFilter, regionItems
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openFilter = () => setIsOpen(true);
  const closeFilter = () => setIsOpen(false);

  const buttonClasses = (): string => {
    const defaultClass = 'map-page-filters__open';
    const activeClass = `${defaultClass}--active`;
    const fillClass = `${defaultClass}--fill`;

    return classnames(defaultClass, { 
      [activeClass]: isOpen || isActive,
      [fillClass]: activeFilter.length > 0 
    });
  };

  const setActiveFilterHandler = (filter: string) => {
    let active: string[] = activeFilter.length > 0 ? activeFilter.split(',') : [];

    if (active.includes(filter)) {
      active = active.filter(item => item !== filter);
    } else {
      active.push(filter);
    }

    setActiveFilter(active.join(','));
  };

  const resetActiveFilter = () => setActiveFilter('');

  return (
    <div className="map-page-filters">
      <MapButton 
        id="filters"
        tip="Фильтр по типам оборудования"
        tipPlace="right"
        className={buttonClasses()}
        onClick={openFilter}
        tipShow={true}
      >
        <FiltersSvg />
      </MapButton>
      <MapBlock 
        active={isOpen}
        title="Фильтры"
        onClose={closeFilter}
      >
        <div className="map-page-filters__content">
          <ButtonGroup>
            {filtersData.map(item => (
              <Button 
                variant="gray"
                key={item.id}
                onClick={() => setActiveFilterHandler(item.slug)}
                active={activeFilter.includes(item.slug)}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
          {regionItems.length === 0 && activeFilter.length > 0 && (
            <p className="map-page-filters__empty">
              Пунктов выдачи оборудования не обнаружено. 
              Измените параметры поиска.
            </p>
          )}
        </div>
        {activeFilter.length > 0 && (
          <div className="map-page-filters__actions">
            <Button variant="gray" onClick={resetActiveFilter}>
              Очистить
            </Button>
          </div>
        )}
      </MapBlock>
    </div>
  );
};

export default Filters;
