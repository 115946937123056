import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveFilter, getFiltersData, getRegionItems } from '../../../store/regions';
import { setActiveFilter } from '../../../store/regions/reducer';
import { useAppDispatch } from '../../../store/rootTypes';
import FiltersView from './Filters';

export type FiltersType = {
  isActive: Boolean;
};

const Filters: React.FC<FiltersType> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const activeFilter = useSelector(getActiveFilter);
  const regionItems = useSelector(getRegionItems);
  const filtersData = useSelector(getFiltersData);

  const setActiveFilterHandler = 
    (filters: string) => dispatch(setActiveFilter(filters));


  useEffect(() => {

    const params = new URLSearchParams(window?.location?.search || '');
    if (params?.get('filter')) {
      setActiveFilterHandler(params.get('filter'))
    }
  }, []);

  return (
    <FiltersView 
      activeFilter={activeFilter}
      filtersData={filtersData}
      setActiveFilterHandler={setActiveFilterHandler}
      regionItems={regionItems}
      {...props}
    />
  );
};

export default Filters;