import React from 'react';
import MapButton from '../../../components/MapButton';
import { ReactComponent as PlusSvg } from './plus.svg';
import { ReactComponent as MinusSvg } from './minus.svg';
import { ReactComponent as GeolocationSvg } from './geolocation.svg';
import './MapControls.scss';

type MapControlsType = {
  incZoom: () => void;
  decZoom: () => void;
  getGeolocation: () => void;
};

const MapControls: React.FC<MapControlsType> = ({
  incZoom, decZoom, getGeolocation
}) => (
  <div className="map-page-control">
    <MapButton 
      id="plus"
      className="map-page-control__plus"
      tip="Приблизить"
      onClick={incZoom}
    >
      <PlusSvg />
    </MapButton>
    <MapButton 
      id="minus"
      className="map-page-control__minus"
      tip="Отдалить"
      onClick={decZoom}
    >
      <MinusSvg />
    </MapButton>
    <MapButton 
      id="geolocation"
      className="map-page-control__geolocation"
      tip="Моё местоположение"
      onClick={getGeolocation}
    >
      <GeolocationSvg />
    </MapButton>
  </div>
);

export default MapControls;
