import classnames from 'classnames';
import React from 'react';
import './ButtonGroup.scss';

type ButtonGroupType = {
  disabled?: boolean
};

const ButtonGroup: React.FC<ButtonGroupType> = ({ 
  children, 
  disabled = false 
}) => (
  <div className={classnames('button-group', { 'button-group--disabled': disabled })}>
    {children}
  </div>
);

export default ButtonGroup;
