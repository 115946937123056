import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import Swipe from 'react-easy-swipe';
import { ReactComponent as Close } from './close.svg';
import useOnClickOutside from '../../hooks/useClickOutside';
import './MapBlock.scss';

type MapBlockType = {
  title: string;
  onClose: () => void;
  active: boolean;
  swipeHeight?: number
};

const MapBlock: React.FC<MapBlockType> = ({ 
  title, children, onClose, active, swipeHeight
}) => {
  const blockRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getBlockClass = (): string => {
    const defaultClass = 'map-block';
    const activeClass = `${defaultClass}--active`;
    const swipeClass = `${defaultClass}--swipe`;

    return classnames(defaultClass, { 
      [activeClass]: active && !swipeHeight || isOpen, 
      [swipeClass]: swipeHeight 
    });
  };

  const getTitleHeight = (): number => {
    if (!blockRef.current) return 28;
    const titleBlock = blockRef.current.querySelector('.map-block__title');
    
    if (!titleBlock || titleBlock.clientHeight === 0) return 28;

    return titleBlock.clientHeight;
  };

  const getBlockStyle = (): React.CSSProperties => {
    if (window.innerWidth > 768 || !swipeHeight || !active) return {};
    const offset = 26 + swipeHeight + getTitleHeight();
    const resultOffset = isOpen ? '100%' : `${offset}px`;

    return {
      transform: `translateY(calc(100% - ${resultOffset}))`
    };
  };

  const onSwipeMove = (position) => {
    if (position.y < 0 && !isOpen) setIsOpen(true);
    if (position.y > 0 && isOpen) setIsOpen(false);
  };

  const onCloseHandler = () => {
    if (isOpen) setIsOpen(false);
    onClose();
  };

  useOnClickOutside(blockRef, onCloseHandler);

  return (
    <div ref={blockRef} className={getBlockClass()} style={getBlockStyle()}>
      {swipeHeight ? (
        // @ts-ignore
        <Swipe onSwipeMove={onSwipeMove}>
          <div className="map-block__swipe" style={{ height: swipeHeight - 6  }} />
        </Swipe>
      ) : ''}
      <button 
        type="button"
        className="map-block__close"
        aria-label="Закрыть блок"
        onClick={onCloseHandler}
      >
        <Close />
      </button>
      <div className="map-block__content">
        <h2 className="map-block__title">{title}</h2>
        {children}
      </div>
    </div>
  );
};

export default MapBlock;
