import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../components/Button';
import SelectRegion from '../../components/SelectRegion';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import Filters from './Filters';
import MapPoint from './MapPoint';
import Map from './Map';
import { ReactComponent as Clear } from '../MainPage/clear.svg';
import './MapPage.scss';
import { getRegion, MapItemType } from '../../store/regions';


type MapPageType = {
  activeCityName: string;
  isLoading: boolean;
  fetchActiveRegion: () => void;
};

Modal.setAppElement('#root');

const MapPage: React.FC<MapPageType> = ({
  activeCityName, isLoading, fetchActiveRegion,
}) => {
  const region = useSelector(getRegion);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<MapItemType | null>(null);
  const [
    geolocationCoordinate, 
    setGeolocationCoordinate
  ] = useState<number[]>([]);

  const closeMapPointHandler = (): void => setActiveItem(null);
  const { value: localRegion } = useLocalStorage<string>('ya_cportal_region');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [isSelectRegion, setIsSelectRegion] = useState<boolean>(false);
  const clickHandler = () => setIsSelectRegion(true);

  const confirmRegion = () => {
    closeModal()
    fetchActiveRegion()
  }

  useEffect(() => {
    console.log('LOCAL REG', localRegion)
    if (!localRegion) {
      openModal()
    }
  }, [])

  if (Object.keys(region).length === 0) return (
      <div className="map-page">
        <main className="map-page__main">
          <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          overlayClassName="select-region-overlay"
          className="select-region-content"
        >
          <button
            type="button"
            className="select-region-content__clear"
            aria-label="Закрыть модальное окно"
            onClick={closeModal}
          >
            <Clear />
          </button>
          <div className="main__block main-block">
            <h2 className="main-block__title">Места выдачи оборудования для пеших и&nbsp;авто курьеров</h2>
            <p className="main-block__subtitle">
              {isSelectRegion ? 'Укажите свой регион' : `Ваш регион ${activeCityName}?`}
            </p>
            {isSelectRegion ? (
              <div className="main-block__select">
                <SelectRegion onFetchRegion={closeModal} />
              </div>
            ) : (
              <div className="main-block__actions">
                <Button
                  variant="outline"
                  onClick={clickHandler}
                  size="lg"
                  pill={false}
                >
                  Выбрать другой
                </Button>
                <Button
                  onClick={confirmRegion}
                  size="lg"
                  pill={false}
                  disabled={isLoading}
                >
                  Верно
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </main>
    </div>
  );

  return (
    <div className="map-page">
      <main className="map-page__main">
        <Filters isActive={!!activeItem} />
        <div className="map-page__block">
          <MapPoint 
            item={activeItem}
            active={!!activeItem}
            onClose={closeMapPointHandler}
            geolocationCoordinate={geolocationCoordinate}
          />
        </div>
        <Map
          regionCenter={region.center}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          geolocationCoordinate={geolocationCoordinate}
          setGeolocationCoordinate={setGeolocationCoordinate}
        />
      </main>
    </div>
  );
};

export default MapPage;
