import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/ru';

import Button from '../../../components/Button';
import ButtonGroup from '../../../components/ButtonGroup';
import MapBlock from '../../../components/MapBlock';
import { MapItemType } from '../../../store/regions';

import { ReactComponent as TripSvg } from './trip.svg';
import { ReactComponent as PhoneSvg } from './phone.svg';
import { ReactComponent as AddressSvg } from './address.svg';
import { ReactComponent as TimeSvg } from './time.svg';
import { ReactComponent as SiteSvg } from './site.svg';
import { ReactComponent as EmailSvg } from './email.svg';
import './MapPoint.scss';

type MapPointType = {
  item: MapItemType | null;
  active: boolean;
  onClose: () => void;
  geolocationCoordinate: number[]
};

const MapPoint: React.FC<MapPointType> = ({ 
  item, active, onClose, geolocationCoordinate
}) => {
  const descrRef = useRef<HTMLSpanElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [isShowTime, setIsShowTime] = useState<boolean>(true);
  const [swipeHeight, setSwipeHeight] = useState<number>(0);

  const toggleIsShowTime = (): void => {
    setIsShowTime(!isShowTime);
  };

  const getTimeButtonClass = (): string => {
    const defaultClass = 'map-point__item';
    const withArrowClass = `${defaultClass}--with-arrow`;
    const activeClass = `${defaultClass}--active`;
    
    return classnames(defaultClass, withArrowClass, {
      [activeClass]: isShowTime
    });
  };

  const getRouteLink = (): string => {
    if (!item) {
      return '#';
    }
    
    return `https://yandex.ru/maps?rtext=${geolocationCoordinate.join(',')}~${item.coordinate.join(',')}`;
  };

  const getNextOpenTime = (time: string = ''): string => {
    moment.locale('ru');
    const date = moment(time);
    const diffDate = date.diff(moment(), 'days');
    const openTime = date.format('HH:mm');
    return diffDate > 1 ? `в ${date.format('dd')} в ${openTime}` : `завтра в ${openTime}`;
  };

  const closeHandler = (): void => {
    onClose();
    setSwipeHeight(0);
  };

  const renderIframe = (link: string) => {
    const mapElements = document.getElementsByTagName('ymaps');
    if (mapElements.length) {
      document.getElementsByClassName('map-page-control')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('map-page-filters')[0].setAttribute('style', 'display: none');
      document.getElementsByClassName('map-block--active')[0].classList.remove('map-block--active');
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', link);
      iframe.setAttribute('class', 'education-block');
      mapElements[0].outerHTML = iframe.outerHTML;
    }
  };

  useEffect(() => {
    if (!listRef.current || !descrRef.current || window.innerWidth > 768) return;
    setSwipeHeight(descrRef.current.offsetHeight + 90 + listRef.current.offsetHeight);
  }, [item, listRef.current]);
  
  return (
    <MapBlock 
      title={item ? item.title : ''}
      active={active}
      onClose={closeHandler}
      swipeHeight={swipeHeight}
    >
      {item && (
        <div className="map-point">
          <span className="map-pint__descr" ref={descrRef}>{item.description}</span>
          <div className="map-point__action">
            <Button
              className="map-point__trip"
              variant="blue"
              tag="a"
              href={getRouteLink()}
              isTargetBlank={true}
            >
              <TripSvg /> Маршрут
            </Button>
            {item.show_education_button && (
              <Button className="map-point__education" onClick={() => renderIframe('https://education.taxi.yandex/webview/registration-hub?data=eyJodWJJZCI6MTB9')}>
              Записаться на обучение
            </Button>
            )}
          </div>
          <div ref={listRef} className="map-point__list map-point__list--swipe">
            {item.phones.map(phoneItem => (
              <div className="map-point__item" key={phoneItem}>
                <PhoneSvg />
                <a href={`tel:${phoneItem}`}>{phoneItem}</a>
              </div>
            ))}
            <div className="map-point__item">
              <AddressSvg />
              <span>{item.address}</span>
            </div>
          </div>
          <div className="map-point__buttons">
            <ButtonGroup disabled={true}>
              {item.equipments.map(equipment => (
                <Button variant="gray" key={equipment}>
                  {equipment}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className="map-point__list">
            {item.phones.map(phoneItem => (
              <div className="map-point__item" key={phoneItem}>
                <PhoneSvg />
                <a href={`tel:${phoneItem}`}>{phoneItem}</a>
              </div>
            ))}
            {item.email && (
              <div className="map-point__item">
                <EmailSvg />
                <a href={`mailto:${item.email}`}>{item.email}</a>
              </div>
            )}
            {item.website && (
              <div className="map-point__item">
                <SiteSvg />
                <Button 
                  tag="a"
                  href={item.website}
                  variant="link"
                  isTargetBlank={true}
                >
                  {item.website}
                </Button>
              </div>
            )}
            <div className="map-point__item">
              <AddressSvg />
              <span>{item.address}</span>
            </div>
            <button 
              type="button"
              className={getTimeButtonClass()}
              onClick={toggleIsShowTime}
            >
              <TimeSvg />
              {item.is_closing ? (
                <span>
                  Закрыто. Откроется {getNextOpenTime(item.next_open_time)}
                </span>
              ) : (
                <span>Открыто до {item.close_time!.substr(0, 5)}</span>
              )}
            </button>
            {isShowTime && (
              <ul className="map-point__time-list">
                {item.working_hours.map(day => (
                  <li className="map-point__time-item" key={day.day}>
                    <span>{day.day}</span>
                    <span>{day.time}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="map-point__footer">
            <span className="map-point__footer-copy">© 2001–{new Date().getFullYear()} ООО «Яндекс»</span>
            <p>
              Ресурс предназначен для использования партнерами 
              сервиса Яндекс.Логистика и носит исключительно 
              информационный характер. Яндекс не несет ответственность 
              за качество товаров и/или услуг, предоставляемых третьими лицами.
            </p>
          </div>
        </div>
      )}
    </MapBlock>
  );
};

export default MapPoint;
