import { combineReducers } from '@reduxjs/toolkit';
import regions from './regions';
import cities from './cities';

export const RootReducer = combineReducers({
  cities,
  regions
});

const createRootReducer = () => RootReducer;

export default createRootReducer;
