import React, { useEffect, useState } from 'react';
import { YMapsApi } from 'react-yandex-maps';
import { MapItemType } from '../../../store/regions';
import MapContainer from '../MapContainer';
import MapControls from '../MapControls';

type MapType = {
  regionCenter: number[];
  geolocationCoordinate: number[];
  activeItem: MapItemType | null;
  setGeolocationCoordinate: (geolocationCoordinate: number[]) => void;
  setActiveItem: (activeItem: MapItemType) => void;
};

const Map: React.FC<MapType> = ({
  regionCenter, 
  geolocationCoordinate, 
  setGeolocationCoordinate, 
  activeItem, 
  setActiveItem
}) => {
  const [
    center, 
    setCenter
  ] = useState<number[]>(regionCenter || [55.75, 37.57]);

  const [zoom, setZoom] = useState<number>(9);
  const [ymapsInst, setYmapsInst] = useState<YMapsApi>({});

  const incZoom = (): void => setZoom(zoom + 1);
  const decZoom = (): void => setZoom(zoom - 1);

  const getGeolocation = (isSetCenter = true): void => {
    if (Object.keys(ymapsInst).length === 0) return;
    ymapsInst.geolocation.get({ provider: 'browser', mapStateAutoApply: true }).then(
      (result) => {
        setGeolocationCoordinate(result.geoObjects.position);
        if (isSetCenter) {
          setCenter(result.geoObjects.position);
          setZoom(16);
        }
      },
      (err) => {
        // eslint-disable-next-line
        console.log(`Ошибка: ${  err}`);
      }
    );
  };

  useEffect(() => {
    if (
      Object.keys(ymapsInst).length === 0 || 
      geolocationCoordinate.length > 0
    ) return;

    getGeolocation(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ymapsInst]);

  useEffect(() => {
    if (Object.keys(ymapsInst).length === 0) return;
    setCenter(regionCenter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionCenter]);

  return (
    <>
      <MapControls 
        incZoom={incZoom}
        decZoom={decZoom}
        getGeolocation={getGeolocation}
      />
      <MapContainer 
        center={center}
        zoom={zoom}
        active={activeItem}
        geolocationCoordinate={geolocationCoordinate}
        onLoadMap={setYmapsInst}
        setActive={setActiveItem}
      />
    </>
  );
};

export default Map;
