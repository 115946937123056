import { ThunkType } from './types';
import { apiFetchCities, apiFetchCity } from './api';
import { setCities, setActiveCity, setIsLoadingCities } from './reducer';

export const fetchCity = (): ThunkType => async (dispatch) => {
  await apiFetchCity().then((res) => {
    dispatch(setActiveCity(res.data));
  });
};

export const fetchCities = (slug?: string): ThunkType => async (dispatch) => {
  dispatch(setIsLoadingCities(true));
  dispatch(setCities([]));

  await apiFetchCities(slug).then((res) => {
    dispatch(setCities(res.data));
  }).finally(() => {
    setTimeout(() => {
      dispatch(setIsLoadingCities(false));
    }, 200);
  });
};