import { createSelector } from 'reselect';
import { FilterType } from '.';
import { RootState } from '../rootTypes';
import { InitialStateType, MapItemType, RegionType } from './types';

export const getRegionsModule = (state: RootState): InitialStateType => 
  state.RootReducer.regions;

export const getRegion = 
  createSelector<RootState, InitialStateType, RegionType>(
    getRegionsModule, 
    regions => regions.region
  );

export const getIsLoading = 
  createSelector<RootState, InitialStateType, boolean>(
    getRegionsModule, 
    regions => regions.isLoading
  );

export const getActiveFilter = 
  createSelector<RootState, InitialStateType, string>(
    getRegionsModule, 
    regions => regions.activeFilter
  );

export const getFiltersData = 
  createSelector<RootState, InitialStateType, FilterType[]>(
    getRegionsModule, 
    regions => regions.filtersData
  );

export const getRegionItems = 
  createSelector<RootState, RegionType, string, FilterType[], MapItemType[]>(
    [getRegion, getActiveFilter, getFiltersData],
    (region, activeFilter, filtersData) => {
      if (activeFilter.length === 0) return region.items;
      const activeFilterItems = activeFilter.split(',').map(slug => filtersData.find(item => item.slug === slug)?.name || '');
      return region.items.filter(item => {
        const notHaveEquip = activeFilterItems.filter(
          filterItem => !item.equipments.includes(filterItem)
        );
        
        return notHaveEquip.length === 0;
      });
    }
  );