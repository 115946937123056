import { AxiosResponse } from 'axios';
import { axiosGet } from '../../helpers/api';
import { FilterType, RegionType } from './types';

export const apiFetchRegion = async (id: string) => {
  try {
    const res: AxiosResponse<RegionType> = await axiosGet(`v1/cities/${id}/locations`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiFetchFilters = async () => {
  try {
    const res: AxiosResponse<{equipments: FilterType[]}> = await axiosGet('v1/filters');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};