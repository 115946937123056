import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CitiesType, CityItemType } from './types';

export const initialState = {
  cities: [] as CitiesType,
  isLoadingCities: false,
  activeCity: {} as CityItemType,
};
 
const cities = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<CitiesType>) => {
      state.cities = action.payload;
    },
    setIsLoadingCities: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCities = action.payload;
    },
    setActiveCity: (state, action: PayloadAction<CityItemType>) => {
      state.activeCity = action.payload;
    }
  },
});

export const { reducer, actions } = cities;
export const { setCities, setActiveCity, setIsLoadingCities } = actions;
export default reducer;