import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../components/Button';
import SelectRegion from '../../components/SelectRegion';
import useLocalStorage from '../../hooks/useLocalStorage';
import { RegionType } from '../../store/regions';
import { ReactComponent as Clear } from './clear.svg';
import './MainPage.scss';

type MainPageType = {
  activeCityName: string;
  isLoading: boolean;
  fetchActiveRegion: () => void;
};

Modal.setAppElement('#root');

const MainPage: React.FC<MainPageType> = ({
  activeCityName, isLoading, fetchActiveRegion
}) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const { value: localRegion } = useLocalStorage<string>('ya_cportal_region');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [isSelectRegion, setIsSelectRegion] = useState<boolean>(false);
  const clickHandler = () => setIsSelectRegion(true);

  const confirmRegion = () => {
    closeModal()
    fetchActiveRegion()
  } 

  useEffect(() => {
    if (!localRegion) {
      openModal()
    }
  }, [])
  
  return (
    <div className="main">
      <section className="main__hero">
        <div className="container">
          <h1 className="main__title">Центры<br />Доставки</h1>
          <p className="main__descr">— это офисы партнеров сервиса Яндекс.Доставка.</p>
        </div>
      </section>
      <footer className="main__footer">
        <div className="container">
          <div className="main__footer-wrap">
            <h2 className="main__footer-title">Зачем нужен<br />Центр Доставки</h2>
            <div className="main__footer-text">
              <p className="title">1.  Пройти обучение</p>
              <p>Научиться пользоваться приложением, узнать лайфхаки по работе в сервисе и получить приоритет за прохождение обучения</p>
              <p className="title">2.  Получить дополнительное оборудование</p>
              <p>Взять в аренду термокороб, велосипед или автомобиль</p>
              <p className="title">3.  Проконсультироваться</p>
              <p>Получить консультацию от сотрудника сервиса</p>
            </div>
          </div>
        </div>
      </footer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="select-region-overlay"
        className="select-region-content"
      >
        <button
          type="button"
          className="select-region-content__clear"
          aria-label="Закрыть модальное окно"
          onClick={closeModal}
        >
          <Clear />
        </button>
        <div className="main__block main-block">
          <h2 className="main-block__title">Места выдачи оборудования для пеших и&nbsp;авто курьеров</h2>
          <p className="main-block__subtitle">
            {isSelectRegion ? 'Укажите свой регион' : `Ваш регион ${activeCityName}?`}
          </p>
          {isSelectRegion ? (
            <div className="main-block__select">
              <SelectRegion onFetchRegion={closeModal} />
            </div>
          ) : (
            <div className="main-block__actions">
              <Button 
                variant="outline"
                onClick={clickHandler}
                size="lg"
                pill={false}
              >
                Выбрать другой
              </Button>
              <Button 
                onClick={confirmRegion}
                size="lg"
                pill={false}
                disabled={isLoading}
              >
                Верно
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MainPage;

