import { createSelector } from 'reselect';
import { RootState } from '../rootTypes';
import { CitiesType, CityItemType, InitialStateType } from './types';

export const getCitiesModule = (state: RootState): InitialStateType => 
  state.RootReducer.cities;

export const getCities = 
  createSelector<RootState, InitialStateType, CitiesType>(
    getCitiesModule, 
    cities => cities.cities
  );

export const getIsLoadingCities = 
  createSelector<RootState, InitialStateType, boolean>(
    getCitiesModule, 
    cities => cities.isLoadingCities
  );

export const getActiveCity = 
  createSelector<RootState, InitialStateType, CityItemType>(
    getCitiesModule, 
    cities => cities.activeCity
  );