import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLocalStorage from '../../hooks/useLocalStorage';
import { fetchCities, getCities, getIsLoadingCities } from '../../store/cities';
import { fetchRegion, getIsLoading, getRegion } from '../../store/regions';
import { useAppDispatch } from '../../store/rootTypes';
import SelectRegionView from './SelectRegion';

type SelectRegionType = {
  onFetchRegion?: () => void
};

const SelectRegion: React.FC<SelectRegionType> = ({ onFetchRegion }) => {
  const { setValue } = useLocalStorage<string>('ya_cportal_region');
  const dispatch = useAppDispatch();
  const region = useSelector(getRegion);
  const cities = useSelector(getCities);
  const isLoadingCities = useSelector(getIsLoadingCities);
  const isLoadingRegion = useSelector(getIsLoading);

  const fetchCitiesHandler = async (query = '') => dispatch(fetchCities(query));
  const fetchRegionHandler = async (id: string) => {
    if (id !== region.id) {
      await dispatch(fetchRegion(id));
      setValue(id);
    }
    onFetchRegion && onFetchRegion();
  };

  useEffect(() => {
    fetchCitiesHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectRegionView 
      cities={cities}
      isLoadingCities={isLoadingCities}
      isLoadingRegion={isLoadingRegion}
      fetchRegionHandler={fetchRegionHandler}
      fetchCitiesHandler={fetchCitiesHandler}
    />
  );
};

export default SelectRegion;