import { ThunkType } from './types';
import { apiFetchFilters, apiFetchRegion } from './api';
import { setFilters, setIsLoading, setRegion } from './reducer';

export const fetchRegion = (id: string): ThunkType => async (dispatch) => {
  dispatch(setIsLoading(true));

  await apiFetchRegion(id).then((res) => {
    dispatch(setRegion(res.data));
  }).finally(() => {
    dispatch(setIsLoading(false));
  });
};

export const fetchFilters = (): ThunkType => async (dispatch) => {
  await apiFetchFilters().then((res) => {
    dispatch(setFilters(res.data.equipments));
  }).finally(() => {
    dispatch(setIsLoading(false));
  });
};