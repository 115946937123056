import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterType, RegionType } from './types';

export const initialState = {
  region: {} as RegionType,
  isLoading: false,
  activeFilter: '',
  filtersData: [] as FilterType[]
};
 
const cities = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<RegionType>) => {
      state.region = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActiveFilter: (state, action: PayloadAction<string>) => {
      state.activeFilter = action.payload;
    },
    setFilters: (state, action: PayloadAction<FilterType[]>) => {
      state.filtersData = action.payload;
    }
  },
});

export const { reducer, actions } = cities;
export const { setRegion, setIsLoading, setActiveFilter, setFilters } = actions;
export default reducer;