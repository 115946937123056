import React from 'react';

const logo = () => (
  <a href="https://pro.yandex/ru-ru/courier-base" target="_blank">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 319.8 58.6">
      <g>
        <path d="M23.8,6.6H20c-6.9,0-10.5,3.5-10.5,8.7c0,5.9,2.5,8.6,7.7,12.1l4.3,2.9L9.1,48.5H0l11-16.4c-6.4-4.5-9.9-8.9-9.9-16.4 C1.1,6.3,7.6,0,20,0h12.3v48.5h-8.4V6.6z" />
        <polygon points="66.9,13.4 66.9,48.5 58.6,48.5 58.6,33.9 47.5,33.9 47.5,48.5 39.2,48.5 39.2,13.4 47.5,13.4 47.5,27.3 58.6,27.3 58.6,13.4" />
        <g>
          <path d="M100.7,13.4H76.5v3c0,8.6-0.6,19.7-3.5,25.5h-2.6v14.6h7.7v-8h18.6v8h7.7V42h-3.7V13.4z M92.4,42H80.7 c2.3-5.3,2.9-14.9,2.9-20.9v-1h8.8V42z" />
          <path d="M135.1,29.5c0-12.2-5.4-16.8-13.7-16.8c-10.1,0-14.9,7.7-14.9,18.4c0,12.2,6,18.1,16.6,18.1c5.3,0,9.2-1.4,11.7-3.1v-6.8 c-2.6,1.7-6.9,3.3-11,3.3c-6.1,0-8.4-2.9-8.7-8.7h20V29.5z M126.6,27.8H115c0.2-5.6,2.2-8.5,6.2-8.5c4.1,0,5.4,3.4,5.4,7.8V27.8z" />
        </g>
        <polygon points="149,31.3 149,48.5 140.7,48.5 140.7,13.4 149,13.4 149,29.6 160.7,13.4 169.1,13.4 157.3,29.6 170.7,48.5 161.2,48.5" />
        <path d="M170.2,31c0-10.7,5.5-18.3,16.1-18.3c4.1,0,7.2,0.7,8.9,1.7v7.1c-1.7-1-4.8-2.2-8-2.2c-5.4,0-8.4,3.9-8.4,11.5 c0,7.3,2.4,11.9,8.4,11.9c3.5,0,6-0.9,8.1-2.4V47c-2,1.5-4.7,2.2-8.6,2.2C175.6,49.2,170.2,42.7,170.2,31z" />
        <path d="M246.9,0v48.5h-8.4v-42h-15.1v42h-8.4V0H246.9z" />
        <path d="M262,17.9c2.2-3.4,5.4-5.2,9.4-5.2c7.8,0,13.3,6.5,13.3,18.2c0,11.8-5.6,18.3-13.7,18.3c-3.6,0-6.6-1.7-8.7-4.8v14.2h-8.3 V13.4h7.7L262,17.9z M276,30.9c0-8.1-2.2-11.7-6.8-11.7c-4.7,0-7.1,3.6-7.1,11.7c0,8.1,2.2,11.7,6.6,11.7 C273.6,42.6,276,39.1,276,30.9z" />
        <path d="M319.8,30.9c0,11.8-6.4,18.3-15.5,18.3c-9.1,0-15.5-6.5-15.5-18.2c0-11.8,6.4-18.3,15.5-18.3 C313.4,12.7,319.8,19.2,319.8,30.9z M311.2,30.9c0-8.1-2.3-11.7-6.9-11.7c-4.6,0-6.9,3.6-6.9,11.7c0,8.1,2.3,11.7,6.9,11.7 C308.9,42.6,311.2,39.1,311.2,30.9z" />
      </g>
    </svg>
  </a>
);

export default logo;

