import React from 'react';
import { YMaps, YMapsApi, Map, Placemark } from 'react-yandex-maps';
import { useSelector } from 'react-redux';
import mappin from './mappin.svg';
import mappinActive from './mappin-active.svg';
import mappinGeolocation from './mappin-geolocation.svg';
import { getRegionItems, MapItemType } from '../../../store/regions';

type MapContainerType = {
  center: number[];
  zoom: number;
  geolocationCoordinate: number[];
  active: MapItemType | null;
  onLoadMap: (inst:YMapsApi) => void;
  setActive: (item: MapItemType) => void
};

const MapContainer: React.FC<MapContainerType> = ({
  center, zoom, geolocationCoordinate, onLoadMap, setActive, active
}) => {
  const regionItems = useSelector(getRegionItems);
  const getOptions = (id: string) => {
    const isActive = active && id === active.id;
    return {
      iconLayout: 'default#image',
      iconImageHref: isActive ? mappinActive : mappin,
      iconImageSize: isActive ? [28, 48] : [24, 24],
      iconImageOffset: isActive ? [-14, -38] : [-12, -12]
    };
  };

  return (
    <YMaps query={{ apikey: 'f1f8a7e6-bf59-4205-8f45-58adcc6eae6f' }}>
      <Map
        modules={['geolocation']}
        onLoad={onLoadMap}
        state={{ center, zoom }}
        width="100%"
        height="100%"
      >
        {regionItems.map(item => (
          <Placemark 
            key={item.id}
            geometry={item.coordinate}
            options={getOptions(item.id)}
            onClick={() => setActive(item)}
          />
        ))}
        {geolocationCoordinate.length > 0 && (
          <Placemark 
            geometry={geolocationCoordinate}
            options={{
              iconLayout: 'default#image',
              iconImageHref: mappinGeolocation,
              iconImageSize: [28, 28],
              iconImageOffset: [-14, -14]
            }}
          />
        )}
      </Map>
    </YMaps>
  );
};

export default MapContainer;
